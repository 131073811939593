import httpClient from './http.client'

class GpsService {
  getMainConditions() {
    return [
      { name: "Wszyscy", condition: "object__user__is_active=true" },
      { name: "Patrole", condition: "object__user__is_patrol=true" }
    ]
  }
  fetch(search = "", conditions = [], id = null) {
    let query = ''
    if (search != null && search.length > 0) {
      query = `search=${search}`
    }
    for (const condition of conditions) {
      if (query.length > 0) {
        query += "&"
      }
      query += `${condition}`
    }
    if (id != null) {
      if (query.length > 0) {
        query += "&"
      }
      query += `id=${id}`
    }
    return httpClient.get(`v1/gps/records?${query}`).then(res => res.data)
  }
}

export default new GpsService()
