<template>
  <page-content fluid>
    <v-toolbar flat>
      <app-tabs v-model="selectedTab">
        <v-tab v-for="tab of tabs" :key="tab.condition">{{ tab.name }}</v-tab>
      </app-tabs>
    </v-toolbar>
    <!-- <app-toolbar fluid> -->
      <v-col cols="4">
        <app-filter-field
          v-model="filter"
          @input="onFilter"
        ></app-filter-field>
      </v-col>
    <!-- </app-toolbar> -->
    <v-container class="root d-flex pa-0 pr-6" fluid>
      <v-layout class="split">
        <section class="left">
          <v-list class="overflow-y-auto mt-auto" two-line width="500">
            <v-list-item-group v-model="selected">
              <template v-for="(item, index) in items">
                <v-list-item :key="item.title" @click="selectedItem(index)">
                  <template v-slot:default="{}">
                    <v-list-item-icon>
                      <user-info
                        dense
                        :routerLink="false"
                        :user="item.gps.object.user"
                      ></user-info>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.car }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle>{{
                        item.headline
                      }}</v-list-item-subtitle>

                      <v-list-item-subtitle>{{
                        item.subtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>{{
                        item.action
                      }}</v-list-item-action-text>
                      <v-list-item-action-text v-if="item.timeDistance < -60">{{
                        item.timeDistance | timeDurationWithSuffix
                      }}</v-list-item-action-text>
                      <!-- <v-icon v-if="!active" color="grey lighten-1">
                      mdi-star-outline
                    </v-icon>
                    <v-icon v-else color="yellow darken-3"> mdi-star </v-icon> -->
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </section>
        <section class="right">
          <gmaps-map :options="map.options">
            <!-- <gmaps-info-window :options="options">
              <p>Example Text</p>
            </gmaps-info-window> -->
            <gmaps-marker
              v-for="(item, i) in markers"
              :key="i"
              :options="item.options"
            />
          </gmaps-map>
        </section>
      </v-layout>
    </v-container>
  </page-content>
</template>

<script>
// import filters from '@/filters/user.js'
// import UserService from "@/services/user.service"
import GpsService from "@/services/gps.service"
import { gmapsMap, gmapsMarker } from "x5-gmaps" //, gmapsInfoWindow, gmaps
// import UserInfo from '../components/UserInfo.vue'
import {
  // formatDistance,
  parseJSON
  // differenceInDays,
  // formatRelative,
} from "date-fns"

export default {
  name: "App",
  data: () => ({
    selected: null,
    selectedTab: 1,
    filter: "",
    tabs: GpsService.getMainConditions(),
    options: {
      position: { lat: -27.46, lng: 153.02 }
    },
    timer: null,
    items: [],
    lastId: null,
    map: {
      options: {
        zoom: 9,
        center: { lat: 54.35, lng: 18.64 }
      },
      actionIcon: "mdi-tag",
      actionText: "",
      color: "primary",
      title: "",
      subtitle: ""
    },
    markers: []
  }),
  watch: {
    selectedTab() {
      this.refresh()
    }
  },
  components: {
    // gmapsInfoWindow,
    gmapsMap,
    gmapsMarker
  },
  computed: {
    // itemDistance() {
    // return (item) =>
    //   (item.counter.getTime() - parseJSON(item.gps.time).getTime())/1000
    // },
  },
  methods: {
    selectedItem(index) {
      if (index >= 0) {
        this.map.options.zoom = 15
        this.map.options.center = {
          lat: this.markers[index].options.position.lat,
          lng: this.markers[index].options.position.lng
        }
      }
    },
    calculateTimeDistance(time) {
      return (parseJSON(time).getTime() - new Date().getTime()) / 1000
    },
    fetchNewRecords() {
      if (this.lastId == null) {
        return
      }
      const conditions = [this.tabs[this.selectedTab].condition]
      GpsService.fetch(this.filter, conditions, this.lastId).then(data => {
        for (const item of this.items) {
          item.timeDistance = this.calculateTimeDistance(item.gps.time)
        }
        for (const gps of data) {
          var index = -1
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].id === gps.object.id) {
              index = i
              break
            }
          }
          if (index != -1) {
            const item = this.items[index]
            item.action = `${gps.speed} km/h`
            item.headline = gps.address
            item.subtitle = `${gps.city}`

            const marker = this.markers[index]
            marker.options.position = {
              lng: gps.location.coordinates[0],
              lat: gps.location.coordinates[1]
            }
          }
        }
      })
    },
    onFilter() {
      this.refresh()
    },
    async refresh() {
      const conditions = [this.tabs[this.selectedTab].condition]
      await GpsService.fetch(this.filter, conditions).then(response => {
        this.items = []
        this.markers = []
        for (const gps of response) {
          this.items.push({
            id: gps.object.id,
            title: this.$options.filters.userName(gps.object.user),
            car: gps.object.name,
            action: `${gps.speed} km/h`,
            headline: gps.address,
            subtitle: `${gps.city}`,
            timeDistance: this.calculateTimeDistance(gps.time),
            gps: gps
          })
          this.lastId = gps.id
        }
        this.items = this.items.sort(function(a, b) {
          return a.title.localeCompare(b.title)
        })
        for (const item of this.items) {
          const gps = item.gps
          this.markers.push({
            id: gps.object.id,
            options: {
              label: {
                text: this.$options.filters.userName(gps.object.user),
                fontWeight: "bold",
                fontSize: "20px",
                className: "map-label"
              },
              title: gps.object.name,
              // icon: gps.object.user.avatar_url,
              position: {
                lng: gps.location.coordinates[0],
                lat: gps.location.coordinates[1]
              }
            }
          })
        }
      })
    }
  },
  beforeMount() {
    this.refresh()
    this.timer = setInterval(this.fetchNewRecords, 5000)
    if (navigator.geolocation) {
      const self = this
      navigator.geolocation.getCurrentPosition(function(position) {
        self.map.options.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="sass">
.root
  height: 70%
  // width: 100%
  position: absolute

.left
  // background-color: teal
  overflow-y: scroll

.right
  flex: 1
  overflow-y: scroll

.map-label
  width: 0px
  stroke-linejoin: round
  -webkit-text-stroke: 1px white
  text-stroke: 1px white
</style>
